import { render } from "./Audit.vue?vue&type=template&id=1e99986f&scoped=true"
import script from "./Audit.vue?vue&type=script&lang=js"
export * from "./Audit.vue?vue&type=script&lang=js"

import "./Audit.vue?vue&type=style&index=0&id=1e99986f&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-1e99986f"

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QCard,QCardSection,QImg});
